import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";

import Schlafgut from "../../images/schlafgut.jpeg";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Die APP für das Babyschlaf-Protokoll" />
      <h1>Babyschlaf-Protokoll APP</h1>
      <p>
        Du suchst eine APP um das Schlaf- und Stillverhalten deines Babys zu
        notieren?
        <br />
        Gratuliere, du hast sie gefunden!
      </p>
      <p>
        Du möchtest das Schlafverhalten deines Kindes für deine:n persönliche:n
        Schlafberater:in protokollieren. In meiner App kannst du das
        Schlafverhalten, die Nahrungs- und Schreizeiten deines Babys oder
        Kleinkindes ganz einfach protokollieren.
      </p>
      <p>
        Die APP hilft dir den Überblick zu behalten. Protokolliere 7 oder mehr
        Tage für die persönliche Schlafberatung. Mache zu den Zeiten Bemerkungen
        und lass anschließend das Protokoll von einem Profi auswerten.
      </p>
      <h2>Die App (iOS, Android)</h2>
      <div className="row">
        <div className="col-sm-2" style={{ padding: 5 }}>
          <img
            src="https://michaelpalmer.de/sandy/app/babyschlaf-protokoll-app-logo-512.png"
            style={{
              width: 50,
              marginTop: 10,
            }}
            className="imagebordered"
            width="50"
            alt="Schlafgut Schlafberatung Schlafprotokoll"
          />
        </div>
        <div className="col-sm-3" style={{ padding: 5 }}>
          <a
            href="https://play.google.com/store/apps/details?id=de.schlafgut_schlafberatung"
            rel="noreferrer"
            target="_blank"
            title="Schlafgut Schlafprotokoll Google Play Store Android App"
          >
            Google Play Store
            <br />
            <img
              src="https://michaelpalmer.de/sandy/app/store_android.png"
              height="40"
              alt="Google Play Store"
              border="0"
            />
          </a>
        </div>
        <div className="col-sm-3" style={{ padding: 5 }}>
          <a
            href="https://apps.apple.com/app/schlafgut-schlafprotokoll/id1605835781"
            rel="noreferrer"
            target="_blank"
            title="Schlafgut Schlafprotokoll Apple App Store iPhone App"
          >
            Apple APP Store
            <br />
            <img
              src="https://michaelpalmer.de/sandy/app/store_iphone.png"
              height="40"
              alt="Apple App Store iPhone App"
              border="0"
            />
          </a>
        </div>
      </div>
      <hr />
      <p>
        Die APP für das persönliche Schlafprotokoll deines Babys oder
        Kleinkindes.
        <br />
        <br />
        <b>Du bist Schlafberater:in?</b> Und suchst eine APP für dich und deine
        Klienten? <br />
        Dann schau hier:{" "}
        <a href="/apps/babyschlaf-protokoll-app-partnerschaft" target="_self">
          babyschlaf-protokoll-app-partnerschaft
        </a>{" "}
        vorbei.
      </p>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
